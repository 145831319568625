import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const WindDamagePage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "wind damage repair services",
	  "alternateName": "wind damage restoration services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "wind damage repair services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "wind damage repair services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "wind damage repair services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "wind damage restoration services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Wind Damage Repair Services"
        description="When major storms hit, water damage typically accompanies it. We'll come out immediately and make sure nothing get sout of hand."
        canonical="https://ecotechrestoration.ca/services/wind-damage-repair-and-restoration/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/wind.jpg"
          quality={95}
          alt="Wind Damage Repair Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Wind Damage Repair &amp; Restoration
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>Do you have a Wind Damage Restoration Emergency?</CallOut>
        <p>
          During extreme storms, high winds and floods can strike your home or
          business without warning and have devastating effects. Time is of the
          essence when faced with storm damage because the more you delay taking
          action, the difficulty and cost of restoration increases. Ecotech has
          IICRC Certified rapid-response restoration experts on call 24/7/365
          with professional grade equipment to provide you guaranteed 100%
          satisfaction that your property will be brought back up to its
          pre-storm condition.
        </p>
        <h2>Minimizing Water Damage</h2>
        <p>
          When major storms hit, water damage typically accompanies it. Gravity
          always pulls water downward so the progression of the damage must be
          contained to already-affected areas of your property to minimize
          damage. Since the water source of entry is not always apparent,
          Ecotech conducts thermography assessments and property surveys to find
          the location and stop further entry of invasive moisture.
        </p>
        <h2>Protective Measures</h2>
        <p>
          When a storm is brewing, you can mitigate damage by being proactive.
          Access points such as windows and doors are vulnerable to penetration
          by water and leaving them unsecured can result in thousands of dollars
          in damage during and after a storm. Ecotech’s rapid response team will
          board up and tarp down your home either before the storm to protect
          your property from water damage or after a storm to seal damaged roofs
          and other water entry points to prevent further damage until repairs
          can be completed.
        </p>
        <h2>Property Restoration</h2>
        <p>
          Water spreads quickly and is not easily contained. This means that
          water gets into places not simply detected or readily dried such as
          under your floorboards, behind baseboards, in walls, attics,
          basements, and crawlspaces. Mold, mildew and bacteria can become
          hazardous in less than 48 hours of saturation so to avoid permanent
          damage the wise choice is to call Ecotech as soon as you think you
          might have suffered water damage from a storm to get a licensed,
          professional and complete dry-out that decontaminates your home.
        </p>
        <SplashTwo title="Wind Damage Repairs" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default WindDamagePage
